import React from 'react'
import { Box, SimpleGrid } from '@chakra-ui/react'
import EventItem from '../EventItem'
import { EmptyGridDefault } from '../../molecules/EmptyGridDefault'
import { EmptyGridSaveTheDate } from '../../molecules/EmptyGridSaveTheDate'
import FancyLoader from '../../atoms/FancyLoader'

const EventsGrid = ({ collection, section, isFetching }) => {
	if (!isFetching) {
		if (collection.length > 0) {
			const items = collection.map((el, i) => <EventItem key={i} item={el} />)
			return (
				<Box w="full" p={['1rem', '2rem']}>
					<SimpleGrid columns={[1, 2, 2, 4]} spacing="1rem">
						{items}
					</SimpleGrid>
				</Box>
			)
		} else {
			if (section && section == 'saveTheDate') {
				return (
					<Box w="full" p="2rem">
						<EmptyGridSaveTheDate />
					</Box>
				)
			} else {
				return (
					<Box w="full" p="2rem" h="100%">
						<EmptyGridDefault filtered={section} />
					</Box>
				)
			}
		}
	}

	return (
		<Box w="full" m="0 auto">
			<FancyLoader minH="calc(100vh - 220px)" />
		</Box>
	)
}

export default EventsGrid
