import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchEvents } from '../../api/app/events'
import usePaginationV2 from './usePaginationV2'
import { useAuthContext } from '../contexts/AuthProvider'
import useQueryAttribute from './useQueryAttribute'

const availableStatuses = ['active', 'inactive', 'created', 'publishing', 'published', 'archived', 'abandoned']

/**
 * Retrieve all events:
 *
 * @param {*} shouldFetchOnMount
 * @returns
 */
const useEvents = (shouldFetchOnMount = false) => {
	const { hasAdminPrivilege, isImpersonated } = useAuthContext()
	const dispatch = useDispatch()

	const filter = useQueryAttribute({
		key: 'filter',
		defaultValue: hasAdminPrivilege || isImpersonated ? availableStatuses : ['active', 'publishing', 'published'],
	})

	const { isFetching, slice, pagination, handlers } = usePaginationV2({
		state: 'events',
		filter: filter,
		filterBy: 'status',
		action: fetchEvents,
	})

	/** Fetch Existing and linked events */
	useEffect(() => {
		if (shouldFetchOnMount) {
			dispatch(
				fetchEvents({
					pageSize: 12,
					page: 0,
					filters: {
						status: ['active', 'publishing', 'published'],
					},
				})
			)
		}
	}, [])

	const events = {
		slice,
		isFetching,
		availableStatuses,
		// Pagination
		pagination,
		// Handlers
		handlers,
	}

	return events
}

export default useEvents
