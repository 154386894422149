/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA } from 'redux-api-middleware'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */
import * as types from '../constants'
import { addFlashMessage } from '../behaviors/flash-messages'
import { retrieveURL } from '@vidday/utils'

/* ******************************
 * FETCH PROFILE BY AUTH USER OR BODY.ID
 ***************************** */

export const fetchProfile = (host = '') => ({
	[RSAA]: {
		types: [types.LOAD_PROFILE_REQUEST, types.LOAD_PROFILE_SUCCESS, types.LOAD_PROFILE_FAILURE],
		// endpoint: `${retrieveURL()}/api/v2/user-profiles/${data.id}`,
		endpoint: retrieveURL() + '/api/account/profile',
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * UPDATE AUTHED USER PROFILE
 ***************************** */

export function updateProfile(data, successCallback) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPDATE_PROFILE_REQUEST,
					{
						type: types.UPDATE_PROFILE_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								let message = 'Profile updated'
								// let message = json.message || 'Profile updated.'
								// send flash for sucess
								dispatch(addFlashMessage('success', message))
								// if (successCallback) {
								//     successCallback(json.data)
								// }
								return json
							})
						},
					},
					types.UPDATE_PROFILE_FAILURE,
				],
				endpoint: `${retrieveURL()}/api/v2/user-profiles/${data.id}`,
				method: 'PUT',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}
