import React from 'react'
import { VStack, Image, Heading, Text, Button } from '@chakra-ui/react'
import useProfile from '../../../hooks/useProfile'
import { Link } from 'react-router-dom'

export const EmptyGridDefault = ({ filtered }) => {
	const { firstName } = useProfile()

	return (
		<VStack spacing="1.5rem" maxWidth="600px" m="0 auto" pt="2rem" alignItems="center" textAlign="center">
			<Image
				src="/assets/images/graphics/animated/vidday-home-share-surprise.svg"
				w="207px"
				h="150px"
				alt="Start VidDay Gift"
			/>

			<Heading as="h2" variant="hero" size="lg" textAlign="center">
				Lights, Camera, Action! 🎬
				{/* {`Hello${firstName ? `, ${firstName}!` : '!'}`} */}
			</Heading>
			{filtered ? (
				<Text variant="title">There are no events with this status.</Text>
			) : (
				<>
					<Text variant="title" maxW="400px">
						Your dashboard is currently empty, but your next great video is just a click away.
					</Text>
					<Button as="a" href="/start-a-vidday">
						Start a VidDay
					</Button>
				</>
			)}
		</VStack>
	)
}
