import React from 'react'
import { Button, IconButton, Flex, Box, Text, useMultiStyleConfig } from '@chakra-ui/react'
import { Cover } from './Cover'
import { MetaTags } from './MetaTags'
import { Actions } from './Actions'
import { updateEvent } from '../../../../api/app/events'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { useEventOccasion } from '../../../hooks/useEventOccasion'
import { ArrowForwardRounded } from '../../atoms/Icon'
const EventItem = ({ item }) => {
	const { id, uuid, status, guestbook } = item

	const recipients = useEventRecipients(item)
	const occasion = useEventOccasion(item)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const goToEvent = () => {
		if (status == 'saveTheDate') {
			var eventData = { id, status: 'active' }
			dispatch(updateEvent(eventData))
		}
		navigate(`/event/${uuid}`)
	}

	const styles = useMultiStyleConfig('NavCard')

	return (
		<Box bg="white" boxShadow="md" borderRadius="lg" position="relative" cursor="pointer" onClick={goToEvent}>
			<Actions event={item} />
			<MetaTags event={item} />
			<Cover event={item} />

			<Flex
				justifyContent="space-between"
				alignItems="center"
				p="4"
				bg="white"
				borderBottomRadius="lg"
				h="80px"
				position="absolute"
				bottom={0}
				left={0}
				right={0}
				cursor="pointer"
				textAlign="left">
				<Box>
					<Text sx={styles.title} lineHeight="24px" textTransform="capitalized" noOfLines={1}>
						{recipients?.formatted}
					</Text>
					<Text sx={styles.description} noOfLines={1}>
						{occasion?.label}
						{guestbook ? ' Guestbook' : ''}
					</Text>
				</Box>

				<Box ml="0.5rem">
					{status == 'saveTheDate' ? (
						<Button>Start</Button>
					) : (
						<IconButton
							aria-label="Go to Event"
							size="md"
							variant="iconCta"
							icon={<ArrowForwardRounded />}
						/>
						// <Button
						// 	colorScheme="teal"
						// 	aria-label="Go to Event"
						// 	size="md"
						// 	w="48px"
						// 	bg="rgb(205, 231, 255)">
						// 	<Icon as={ArrowForwardRoundedIcon} color="link" />
						// </Button>
					)}
				</Box>
			</Flex>
		</Box>
	)
}

export default EventItem
