import { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

const usePagination = ({ state, action, filter, filterBy }) => {
	const selectFromState = (s, state) => {
		return s['entities'][state]
	}

	const isFetching = useSelector((s) => selectFromState(s, state).isFetching, shallowEqual)
	const reduxEntities = useSelector((s) => selectFromState(s, state).entities, shallowEqual)
	const pageSize = useSelector((s) => selectFromState(s, state).pageSize, shallowEqual)
	const lastPage = useSelector((s) => selectFromState(s, state).lastPage, shallowEqual)
	const nextPage = useSelector((s) => selectFromState(s, state).nextPage, shallowEqual)
	const prevPage = useSelector((s) => selectFromState(s, state).prevPage, shallowEqual)

	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(0)
	const [currentFilter, setCurrentFilter] = useState(filter)
	const [slice, setSlice] = useState([])

	/**
	 * Set slice as array
	 */
	useEffect(() => {
		if (reduxEntities) {
			setSlice(Object.values(reduxEntities))
		}
	}, [reduxEntities])

	/**
	 * Fetch call to dispatch
	 */
	useEffect(() => {
		if (!isFetching && filter) {
			let reset = currentFilter && filter != currentFilter
			console.log('reset', filter != currentFilter, filter, currentFilter)
			dispatch(
				action({
					pageSize: pageSize,
					page: reset ? 0 : currentPage,
					filters: {
						[filterBy]: filter,
					},
				})
			)
			setCurrentFilter(filter)
			reset && setCurrentPage(0) // Reset page to 0 on new filter
		}
	}, [currentPage, filter])

	const handleScrollTop = () => {
		if (typeof window != 'undefined') window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const goFirst = () => {
		setCurrentPage(0)
		handleScrollTop()
	}
	const goLast = () => {
		setCurrentPage(lastPage)
		handleScrollTop()
	}
	const goTo = (i) => {
		setCurrentPage(i)
		handleScrollTop()
	}

	const goPrevious = () => {
		currentPage == 0 ? setCurrentPage(0) : setCurrentPage(currentPage - 1)
		handleScrollTop()
	}

	const goNext = () => {
		currentPage == lastPage ? setCurrentPage(lastPage) : setCurrentPage(currentPage + 1)
		handleScrollTop()
	}

	return {
		isFetching,
		slice,
		pagination: {
			filter: filter,
			pageSize: pageSize,
			currentPage: currentPage,
			lastPage: lastPage,
			nextPage: nextPage,
			prevPage: prevPage,
		},
		// Handlers
		handlers: {
			goFirst,
			goLast,
			goTo,
			goNext,
			goPrevious,
		},
	}
}

export default usePagination
