import { useSelector, shallowEqual } from 'react-redux'
import { useMediaSortingOrder } from './useMediaSortingOrder'

/**
 * Retrieve media of the current event (can be improved to retrieve media of any event)
 * @param {*} event
 * @returns
 */
export const useMedia = () => {
	/** Retrieve all medias from the store */
	const media = useSelector((s) => s.event.media, shallowEqual)
	const mediaSortingOrder = useMediaSortingOrder()
	const sortedMedia = media?.filter(
		(item) => mediaSortingOrder.length && mediaSortingOrder?.includes(item.id || item.mediaUuid)
	)
	return sortedMedia
}
