import { useEffect, useState } from 'react'
import { useEventTheme } from './useEventTheme'

/**
 * Retrieve or compute the cover photo of an event that should be returned.
 * @returns
 */
export const useEventCoverPhotoPath = (event) => {
	/** Compute theme being used by this event */
	const theme = useEventTheme(event)

	/** Set path of cover photo used for saveTheDate events */
	const [saveTheDateCover] = useState('/assets/images/graphics/vidday-saved-the-date.jpg')
	const [machine] = useState('/assets/images/graphics/machine.svg')

	/** Finale cover photo that we're computing */
	const [path, setPath] = useState(null)

	useEffect(() => {
		if (event) {
			if (event.coverPhoto && event.coverPhoto.signedThumbnailUrl) {
				setPath(event.coverPhoto.signedThumbnailUrl)
			} else {
				let url = ''
				if (event.status == 'saveTheDate') {
					url += saveTheDateCover
				} else if (theme?.thumb) {
					url += theme.thumb
				} else {
					url += machine
				}

				url += `?t=${new Date(event.updatedAt).getTime()}`
				setPath(url)
			}
		}
	}, [event, theme])

	return path
}
