import React from 'react'
import { useDispatch } from 'react-redux'
import { archiveEvent } from '../../../../api/app/events'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalArchiveEvent = ({ onClose, isOpen, event }) => {
	const dispatch = useDispatch()
	let eventData = { id: event.id, uuid: event.uuid, status: 'archived' }

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/icons/icon_warning.svg'} alt="Preview Started" />
						<Heading size="lg" variant="hero">
							Delete this event?
						</Heading>
						<Text>Are you sure you want to delete this event?</Text>
						<Text>It will no longer be available to view.</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								dispatch(archiveEvent(eventData))
								onClose()
							}}>
							Yes, I'm sure
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalArchiveEvent
