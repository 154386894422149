import React from 'react'
import { Box, Container, HStack, useColorModeValue } from '@chakra-ui/react'
import DashboardLink from '../../atoms/DashboardLink'
import useEvents from '../../../hooks/useEvents'
import { Helmet } from 'react-helmet'
import EventsGrid from '../../organisms/EventsGrid'
import { useAuthContext } from '../../../contexts/AuthProvider'
import Pagination from '../../molecules/Pagination'

const Dashboard = () => {
	const { hasAdminPrivilege, isImpersonated } = useAuthContext()
	const { isFetching, slice, pagination, handlers } = useEvents(true)
	const filter = pagination.filter

	return (
		<>
			<Helmet>
				<title>VidDay - Dashboard</title>
				<meta name="robots" content="noindex nofollow noarchive" />
			</Helmet>

			<Box>
				<Box as="nav" width="full" boxShadow="base" bg={useColorModeValue('white', 'gray.900')}>
					<Container maxW="container.xl">
						<HStack
							h="80px"
							spacing={4}
							px={4}
							w="full"
							overflowX="auto"
							sx={{
								'scrollbarWidth': 'none',
								' msOverflowStyle': 'none',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
							}}>
							<DashboardLink isActive={Array.isArray(filter)} to="/dashboard">
								All VidDays
							</DashboardLink>

							<DashboardLink isActive={filter == 'active'} to="/dashboard?filter=active">
								Active
							</DashboardLink>

							<DashboardLink isActive={filter == 'published'} to="/dashboard?filter=published">
								Finalized
							</DashboardLink>

							{(hasAdminPrivilege || isImpersonated) && (
								<>
									<DashboardLink isActive={filter == 'publishing'} to="/dashboard?filter=publishing">
										Publishing
									</DashboardLink>
									<DashboardLink isActive={filter == 'abandoned'} to="/dashboard?filter=abandoned">
										Abandoned
									</DashboardLink>
									<DashboardLink isActive={filter == 'archived'} to="/dashboard?filter=archived">
										Archived
									</DashboardLink>
								</>
							)}
						</HStack>
					</Container>
				</Box>
			</Box>
			{pagination.lastPage > 0 && <Pagination pagination={pagination} handlers={handlers} mt="2rem" />}
			<Container maxW="container.xl" p="0">
				<EventsGrid collection={slice} filter={filter} isFetching={isFetching} />
			</Container>
		</>
	)
}

export default Dashboard
