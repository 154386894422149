import React from 'react'
import { Box } from '@chakra-ui/react'

export const Overlay = ({ transparent, children, ...rest }) => {
	return (
		<Box
			cursor="pointer"
			pos="absolute"
			top="0"
			left="0"
			right="0"
			bottom="80px"
			zIndex="1"
			bg={transparent ? 'transparent' : 'blackAlpha.600'}
			{...rest}>
			{children}
		</Box>
	)
}
