import { useState, useEffect } from 'react'
import useQuery from './useQuery'

const useQueryAttribute = ({ key, defaultValue }) => {
	const query = useQuery() // useMemo function
	/**
	 * Hold reference to the query parameter
	 * */
	const [attr, setAttr] = useState()

	useEffect(() => {
		if (query.get(key) && query.get(key) != attr) {
			setAttr(query.get(key))
		} else {
			setAttr(defaultValue)
		}
	}, [query, key])

	return attr
}

export default useQueryAttribute
