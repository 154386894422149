import { useMemo } from 'react'
/** Review this hook */
import { useVideoDurationCalc } from './useVideoDurationCalc'
import uniqBy from 'lodash/uniqBy'
import { useMedia } from './useMedia'

export const useComputedData = (event) => {
	const { media, mediaSortingOrder, themeTemplateId, lastViewed, creatorId } = event
	/** Retrieve sorted media of the event */
	const sortedMedia = media?.filter(
		(item) => mediaSortingOrder?.length && mediaSortingOrder?.includes(item.id || item.mediaUuid)
	)
	// Get video length from media
	const { durationTime } = useVideoDurationCalc(sortedMedia, themeTemplateId)

	// Compute video length
	const videoLength = useMemo(() => {
		return durationTime ? durationTime : '0:00'
	}, [durationTime, media, themeTemplateId])

	// Get contributor count of the event
	const newContributorsLength = useMemo(() => {
		let mediaWithContributor = sortedMedia?.filter(
			(m) => m.createdAt >= lastViewed && m.contributorId != creatorId && m.contributorId != null
		)
		let uniqueContributors = uniqBy(mediaWithContributor, 'contributorId')
		return uniqueContributors.length
	}, [media])

	const contributorsLength = useMemo(() => {
		if (sortedMedia && sortedMedia.length > 0) {
			let mediaWithContributor = sortedMedia?.filter((m) => m.contributorId != null)
			let uniqueContributors = uniqBy(mediaWithContributor, 'contributorId')
			return uniqueContributors.length
		} else return 0
	}, [media])

	// Get uniq media count of the event
	const mediaCount = useMemo(() => {
		// let mediaWithContributor = media?.filter((m) => m.contributorId != creatorId && m.contributorId != null)
		// let uniqueContributors = uniqBy(mediaWithContributor, 'contributorId')
		// return uniqueContributors.length
		return sortedMedia?.length || 0
	}, [media?.length])

	return {
		videoLength,
		newContributorsLength,
		contributorsLength,
		mediaCount,
	}
}
