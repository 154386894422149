import sortBy from 'lodash/sortBy'
import useFilteredRSVP from './useRSVPFilter'
import { useSelector, shallowEqual } from 'react-redux'

/**
 * Sort method to filter a collection of rsvps based on the userProfile first Name
 * @param {*} col Collection to filter
 * @param {*} sort The iteratees to sort by
 * @returns
 */
export const sortCollection = (col, sort = ['user.profile.firstName']) => sortBy(col, sort)

/**
 * Retrieve event's rsvps
 * @returns
 */
export const useRSVPs = () => {
	/** Retrieve all medias from the store */
	const rsvps = useSelector((s) => s.event.rsvps, shallowEqual)
	return rsvps
}

export const useRSVPFilters = () => {
	/** Retrieve all medias from the store */
	const filters = useSelector((s) => s.event.rsvpFilters, shallowEqual)
	return filters
}

export const useRSVPFiltered = () => {
	const rsvps = useRSVPs()
	const filters = useRSVPFilters()
	const filteredRsvps = useFilteredRSVP(rsvps)

	let col = []
	if (filters.length == 0) {
		col = [...col, ...filteredRsvps.all]
	} else {
		Object.keys(filteredRsvps).forEach((key, i) => {
			if (filters.includes(key)) {
				col = [...col, ...filteredRsvps[key]]
			}
		})
	}
	const collection = sortCollection(col)

	return { rsvps, collection, filters }
}
