import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useComputedData } from '../../../hooks/useComputedData'

import { PlayArrowRounded, EventAvailableRounded, SupervisorAccountRounded, PermMediaRounded } from '../../atoms/Icon'

export const MetaTags = ({ event }) => {
	const { status, deadline } = event
	const date = new Date(deadline)
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	const formattedDate = `${monthNames[new Date(date).getMonth()]} ${new Date(date).getDate()}, ${new Date(
		date
	).getFullYear()}`

	const { videoLength, newContributorsLength, contributorsLength, mediaCount } = useComputedData(event)

	return (
		<Box
			pos="absolute"
			zIndex="2"
			left="0"
			right="0"
			p="1rem"
			bottom="80px"
			display="flex"
			justifyContent="space-between"
			cursor="pointer">
			{status == 'saveTheDate' ? (
				<Text
					display="flex"
					alignItems="center"
					fontSize="1rem"
					fontWeight="semibold"
					borderRadius="full"
					py="0.5rem"
					pl="0.5rem"
					pr="1rem"
					bg="#FF1C63"
					color="white"
					cursor="pointer">
					<EventAvailableRounded mr="0.25rem" />
					{formattedDate}
				</Text>
			) : (
				<>
					<Text
						display="flex"
						alignItems="center"
						size="md"
						fontSize="1rem"
						fontWeight="semibold"
						borderRadius="full"
						py="0.5rem"
						pl="0.5rem"
						pr="1rem"
						bg="blackAlpha.800"
						color="white"
						cursor="pointer">
						<PlayArrowRounded mr="0.25rem" /> {videoLength}
					</Text>
					<Text
						display="flex"
						alignItems="center"
						size="md"
						fontSize="1rem"
						fontWeight="semibold"
						borderRadius="full"
						py="0.5rem"
						px="1rem"
						bg="blackAlpha.800"
						color="white"
						cursor="pointer">
						<SupervisorAccountRounded w="1.25rem" mr="0.25rem" /> {contributorsLength}
						<PermMediaRounded w="1.25rem" ml="0.5rem" mr="0.25rem" /> {mediaCount}
					</Text>
				</>
			)}
		</Box>
	)
}

MetaTags.defaultProps = {
	event: {
		status: null,
		deadline: null,
		media: [],
		themeTemplateId: null,
	},
}
