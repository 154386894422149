import { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useAuthContext } from '../contexts/AuthProvider'
import { fetchProfile } from '../../api/app/profile'

const useProfile = () => {
	const reduxProfile = useSelector((s) => s.auth.profile, shallowEqual)
	/** We should define a default profile */
	const [profile, setProfile] = useState({ firstName: '', lastName: '', ...reduxProfile })
	const dispatch = useDispatch()
	const { userId } = useAuthContext()
	/** Set on mount */
	useEffect(() => {
		if (reduxProfile) {
			setProfile(reduxProfile)
		} else {
			/** Retrieve all from backend if not loaded */
			dispatch(fetchProfile(userId))
		}
	}, [])

	return profile
}

export default useProfile
