import React, { useState, useEffect } from 'react'

/**
 * Available Filters
 */
export const availablefilters = ['', 'completed', 'confirmed', 'awaiting', 'pending', 'archived', 'declined']

/**
 * Filtering methods for each filter state
 */
const filterAll = (col) => col.filter((c) => c.archived == false)
const filterCompleted = (col) =>
	col.filter((c) => (c.status == 'completed' || c.status == 'participated') && c.archived == false)
// const filterParticipated = (col) => col.filter((c) => c.status == 'participated' && c.archived == false)
const filterConfirmed = (col) => col.filter((c) => c.status == 'confirmed' && c.archived == false)
const filterAwaiting = (col) => col.filter((c) => c.status == 'awaiting' && c.archived == false)
const filterPending = (col) => col.filter((c) => c.status == 'pending' && c.archived == false)
const filterArchived = (col) => col.filter((c) => c.archived == true)
const filterDeclined = (col) => col.filter((c) => c.status == 'declined')

/**
 * Hook that accept a collection and a boolean for returning counts only.
 * @param {*} collection
 * @param {*} countOnly
 * @returns
 */

const useFilteredRSVP = (collection, countOnly) => {
	const [all, setAll] = useState(filterAll(collection))
	const [completed, setCompleted] = useState(filterCompleted(collection))
	// const [participated, setParticipated] = useState(filterParticipated(collection))
	const [confirmed, setConfirmed] = useState(filterConfirmed(collection))
	const [awaiting, setAwaiting] = useState(filterAwaiting(collection))
	const [pending, setPending] = useState(filterPending(collection))
	const [archived, setArchived] = useState(filterArchived(collection))
	const [declined, setDeclined] = useState(filterDeclined(collection))

	/**
	 * Update RSVPs when collection change
	 * */
	useEffect(() => {
		if (collection) {
			setAll(filterAll(collection))
			setCompleted(filterCompleted(collection))
			// setParticipated(filterParticipated(collection))
			setConfirmed(filterConfirmed(collection))
			setAwaiting(filterAwaiting(collection))
			setPending(filterPending(collection))
			setArchived(filterArchived(collection))
			setDeclined(filterDeclined(collection))
		}
	}, [collection])

	if (countOnly) {
		return {
			all: all.length.toString(),
			completed: completed.length.toString(),
			// participated: participated.length.toString(),
			confirmed: confirmed.length.toString(),
			awaiting: awaiting.length.toString(),
			pending: pending.length.toString(),
			archived: archived.length.toString(),
			declined: declined.length.toString(),
			rsvps: (confirmed.length + awaiting.length).toString(),
			removed: (archived.length + declined.length).toString(),
		}
	}

	return {
		all,
		completed,
		// participated,
		confirmed,
		awaiting,
		pending,
		archived,
		declined,
		rsvps: [...confirmed, ...awaiting],
		removed: [...archived, ...declined],
	}
}

export default useFilteredRSVP
