import React from 'react'
import { IconButton } from '@chakra-ui/react'

export const OverlayIconButton = ({ ...rest }) => {
	return (
		<IconButton
			pos="absolute"
			transform="translate(-50%, -50%)"
			top="50%"
			left="50%"
			aria-label="Button"
			size="md"
			w="48px"
			border="2px"
			borderColor="white"
			{...rest}
		/>
	)
}
