import React from 'react'
import { Box, AspectRatio, Image } from '@chakra-ui/react'
import { useEventCoverPhotoPath } from '../../../hooks/useEventCoverPhotoPath'
import { Overlay } from './Overlay'
import { OverlayIcon } from './OverlayIcon'
import { OverlayIconButton } from './OverlayIconButton'
import { AnimatedFinalizingGraphic, SaveTheDateGraphic } from './Graphics'
import { PlayArrowRounded } from '../../atoms/Icon'

export const Cover = ({ event }) => {
	/** Retrieve the cover photo of the item */
	const coverPhotoURL = useEventCoverPhotoPath(event)
	const { status } = event

	return (
		<Box borderRadius="lg" overflow="hidden" position="relative">
			{status == 'published' && (
				<Overlay transparent>
					<OverlayIconButton icon={<PlayArrowRounded />} aria-label="Play Video" />
				</Overlay>
			)}
			{status == 'publishing' && (
				<Overlay>
					<OverlayIcon as={AnimatedFinalizingGraphic} />
				</Overlay>
			)}
			{status == 'saveTheDate' && (
				<Overlay transparent>
					<OverlayIcon as={SaveTheDateGraphic} />
				</Overlay>
			)}

			<AspectRatio ratio="1" cursor="pointer">
				<Image src={coverPhotoURL} alt="Cover Image" h="100%" />
			</AspectRatio>
		</Box>
	)
}
