import React from 'react'
import { VStack, Image, Heading, Text, Button } from '@chakra-ui/react'

export const EmptyGridSaveTheDate = () => {
	return (
		<VStack spacing="1.5rem" maxWidth="600px" m="0 auto">
			<Image src="/assets/images/graphics/reminder.svg" w="207px" h="150px" alt="Reminder" />

			<Heading as="h2" variant="hero" size="xl" textAlign="center">
				Want to save the date for an important upcoming occasion?
			</Heading>

			<Text>You have no saved occasions at the moment &mdash; plan ahead.</Text>

			<Button as="a" href="https://www.vidday.com/save-the-date">
				Save a Date
			</Button>
		</VStack>
	)
}
