import { useMemo } from 'react'
import { automationTemplates } from '@vidday/data'

/**
 * Hook that returns the theme templates corresponding to the theme template id specified on the event.
 * @param {*} event
 */
export const useEventTheme = (event) => {
	const theme = useMemo(() => {
		return automationTemplates.find((i) => event.themeTemplateId === i.id)
	}, [event.themeTemplateId])

	return theme
}
