import React from 'react'
import { Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

const styles = {
	pt: '1.75rem',
	h: 'full',
	fontSize: 'md',
	position: 'relative',
	minW: 'max-content',
	_after: {
		content: '""',
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		borderTopRadius: 'base',
		height: '5px',
		backgroundColor: 'transparent',
	},
}

export default ({ isActive, children, ...rest }) => {
	if (isActive) {
		return (
			<Link
				sx={{
					...styles,
					fontWeight: 'bold',
					_hover: {
						textDecoration: 'none',
						cursor: 'default',
					},
					_after: {
						...styles._after,
						backgroundColor: 'gray.900',
					},
				}}
				{...rest}>
				{children}
			</Link>
		)
	}

	return (
		<Link
			as={RouterLink}
			sx={{
				...styles,
				color: '#6C7A88',
				fontWeight: 'semibold',
				_hover: {
					'color': 'link',
					'&:after': {
						backgroundColor: 'link',
					},
				},
			}}
			{...rest}>
			{children}
		</Link>
	)
}
