import React from 'react'
import { Button, HStack } from '@chakra-ui/react'
import { LastPageRounded, FirstPageRounded, PrevPageRounded, NextPageRounded } from '../../atoms/Icon'

const Pagination = ({ pagination, handlers, ...rest }) => {
	const { lastPage, currentPage, prevPage, nextPage } = pagination
	const { goFirst, goLast, goTo, goPrevious, goNext } = handlers

	const Buttons = () => {
		let btns = []
		for (let index = 0; index <= lastPage; index++) {
			btns.push(
				<Button key={index} onClick={() => goTo(index)} variant="pageItem" isActive={currentPage == index}>
					{index + 1}
				</Button>
			)
		}
		return btns.slice(
			currentPage > 1 ? currentPage - (currentPage > lastPage - 1 ? 2 : 1) : 0,
			currentPage < 1 ? currentPage + 3 : currentPage + 2
		)
	}

	if (lastPage > 0) {
		return (
			<HStack w="full" justifyContent="center" {...rest}>
				<Button
					onClick={goFirst}
					disabled={currentPage == 0}
					title="First"
					style={{ opacity: currentPage > 1 && prevPage ? 1 : 0 }}
					variant="pageArrow">
					1
				</Button>
				<Button onClick={goPrevious} disabled={currentPage == 0} title="Previous" variant="pageArrow">
					<PrevPageRounded />
				</Button>
				<Buttons />
				<Button onClick={goNext} disabled={currentPage == lastPage} title="Next" variant="pageArrow">
					<NextPageRounded />
				</Button>
				<Button
					onClick={goLast}
					disabled={currentPage == lastPage}
					title="Last"
					style={{ opacity: lastPage > 4 && nextPage ? 1 : 0 }}
					variant="pageArrow">
					{lastPage + 1}
				</Button>
			</HStack>
		)
	} else {
		return <HStack w="full" justifyContent="center" {...rest} minH="2rem"></HStack>
	}
}
export default Pagination
